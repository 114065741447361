export const columns_config = () => {
	return [{
			name: "country",
			nameEn: "country",
			data: 'country',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		{
			name: "remoteLevel",
			nameEn: "remoteLevel",
			data: 'remoteLevel',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "zipCode",
			nameEn: "zipCode",
			data: 'zipCode',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "remark",
			nameEn: "remark",
			data: 'remark',
			type: 'text',
		},

		// {
		// 	name: "ID",
		// 	data: 'id',
		// 	type: 'text',
		// 	readOnly: true
		// },
	];
}


export const nestedHeadersTop_config = () => {
	return [
		// [{
		// 		label: '发货信息',
		// 		colspan: 21
		// 	},
		// 	{
		// 		label: '追踪信息(自供面单时必填)tracking Info',
		// 		colspan: 2
		// 	},
		// 	{
		// 		label: '保险和签名服务(只用于平台出单)',
		// 		colspan: 2
		// 	},
		// 	'',
		// 	'',
		// ]
	];
}